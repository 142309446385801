import React from "react"

class Benifits extends React.Component {

  render() {
    return (
      <section className="benifor nftlaunch pt-100 mb-0">
          <div className="container"> 
            <h2 className="heading-h2 text-center"><span className="bluecolor">Key Components </span> Of Our NFT Launchpad Development
            </h2>
            <p className="text-center mw1030">
            Our NFT launchpad developers combine the edges of blockchain technology with astonishing features to create a best-in-class launchpad for NFT.
            </p>
            <div className="d-lg-flex flex-wrap add-one">
              <div className="square">
                <h3>Quicker NFT Projects</h3>
                <p className="pharagraph">
                Our NFT launchpad platform ensures users’ convenience as a top priority and allows them to trade NFTs easily. Thus the NFT artist will have an impressive portfolio to showcase their creations to global investors. Also, the users will get quicker updates regarding the NFT projects.
                </p>
              </div>
              <div className="square">
                <h3>NFT Project Audit</h3>
                <p className="pharagraph">
                Platform security and project auditing are the prime concerns while listing a token in the NFT launchpad. We create launchpads with advanced security modules to ensure platforms’ reliability. Also, our enriched development process enhances the launchpad capabilities to conduct project auditing transparently.
                </p>
              </div>
              <div className="square">
                <h3>Wallet Integration</h3>
                <p className="pharagraph">
                Enable your NFT users to experience the next-generation crypto wallet functionalities at their convenience. Our launchpads are highly compatible with prominent crypto wallets like MetaMask, Trust Wallet, and many more. Hence the transactions in the NFT launchpad will be quicker and easier.
                </p>
              </div>
              <div className="square">
                <h3>Payment Gateways</h3>
                <p className="pharagraph">
                Payments Gateways leverage top-notch security while buying and selling Non-Fungible Tokens with an NFT launchpad. We integrate an advanced payment processing system with a launchpad for establishing endless fiat-to-crypto and crypto-to-fiat transactions. This enables seamless payments between the platform and investors.
                </p>
              </div>
              <div className="square">
                <h3>KYC Verification</h3>
                <p className="pharagraph">
                Our NFT launchpad platforms have inbuilt KYC modules to manage ‘Know Your Customer’ proceedings securely and signify the platform's credibility among users. The investors will be approved through KYC verification, thus ensuring the authenticated trading activities in the Launchpad platform.
                </p>
              </div>
              <div className="square">
                <h3>Native Tokens</h3>
                <p className="pharagraph">
                Native Tokens facilitate the launchpad operations like transactions, providing premium access to the platform features and more. Our NFT developers will guide you to create native tokens on various standards to depict the platform's significance among the global pool of investors.
                </p>
              </div>
              <div className="square">
                <h3>Cost Effectiveness</h3>
                <p className="pharagraph">
                Our NFT launchpad solution is compatible with diverse blockchain networks. Our professional experts suggest the relevant blockchains to establish launchpad services at an affordable cost. This helps you establish a cost-effective platform’s services without leveraging the quality at any cost.
                </p>
              </div>
              <div className="square">
                <h3>Liquidity Pool</h3>
                <p className="pharagraph">
                Our NFT launchpad solutions have definitive liquidity pools that allow NFT token holders to leverage their tokens for liquidity and earn profits. The upgraded liquidity will enhance the platform’s functionality and help you gain higher capitalization than your counterparts.
                </p>
              </div>
              <div className="square">
                <h3>Higher ROI</h3>
                <p className="pharagraph">
                We create NFT launchpads that are highly authentic and customized as per the client’s business model. Adopting the latest innovative stacks, our launchpads are capable of producing a high return on investment.
                </p>
              </div>
            </div>
          </div>
      </section>
    )
  }
}

export default Benifits
