import React from "react"

class Benificial extends React.Component {

  render() {
    return (
      <section className="smart-cont nftlaunch pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center"><span className="bluecolor">Astonishing Benefits </span>Of Our NFT Launchpad Development
            </h2>
            <p className="text-center mw1030">
            NFT launchpad development helps NFT startups and creators to market their NFTs efficiently, leveraging extraordinary business benefits. Here are some of the top benefits to highlight.
            </p>
            <div className="d-lg-flex flex-wrap add-one">
              <div className="square" >
                <h3>Broad Spectrum of NFT</h3>
                <p className="pharagraph">
                Around the globe, NFT investments and creations are growing in a steady phase across diverse industries. Our NFT launchpad development meets both investors' and creators' expectations in marketing their NFTs.
                </p>
              </div>
              <div className="square" >
                <h3>High Royalty</h3>
                <p className="pharagraph">
                Our enhanced NFT launchpad platform offers rewards to both investors and creators. Leveraging such benefits will create high royalty among users and keep their active engagement towards the Launchpad platform.
                </p>
              </div>
              <div className="square" >
                <h3>Fair NFT Distribution</h3>
                <p className="pharagraph">
                We develop NFT launchpads that meet the expectations of global fundraisers and investors. Thus you can level up your business standards providing maximum crowdfunding and investment potential through fair NFT distribution.
                </p>
              </div>
              <div className="square" >
                <h3>Maximized Liquidity</h3> 
                <p className="pharagraph">
                Our NFT launchpad enables creators and investors to build an impressive portfolio that drives more impressions from users. This results in the progressive flow of NFT transactions to maximize platform liquidity and user bonding.
                </p>
              </div>
              <div className="square" >
                <h3>Improved Capitalization</h3> 
                <p className="pharagraph">
                Our NFT launchpad allows fundraisers to conduct pre and post-NFT sales that increase the capitalization potential. Enabling easy access to investors will enhance the chances of gaining more capital and higher ROI.
                </p>
              </div>
              <div className="square" >
                <h3>Reliability As Priority</h3>
                <p className="pharagraph">
                Our NFT launchpad is designed to support multiple NFT projects at a time with utmost performance and scalability. Also, the platform qualifies for security and legal regulatory norms maintaining trustworthiness.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Benificial
