import React from "react"

class Benificial extends React.Component {

  render() {
    return (
      <section className="fine-box versatile pt-100 mb-0">
          <div className="gray-bg"> 
            <div className="container"> 
              <h2 className="heading-h2 text-center">Versatile Fundraising Models Supported By Our <span className="bluecolor">NFT Launchpad Development</span>
              </h2>
              <p className="text-center mb-2">
              Unlock the maximum proportion of fundraising with our NFT launchpad development services that support various fundraising models.
              </p>
              <div className="d-lg-flex flex-wrap add-one">
                <div className="square" >
                  <h3>Initial Coin Offering</h3>
                  <p className="pharagraph">
                  Initial Coin Offering is a pioneering fundraising model in which the NFT project owner sells tokens to investors and raises funding for their NFT project. The investor will receive tokens and governance rights and can participate in the project's growth.
                  </p>
                </div>
                <div className="square" >
                  <h3>Initial DEX Offering</h3>
                  <p className="pharagraph">
                  Initial DEX Offering simplifies the fundraiser's efforts in conducting marketing and promotional activities. The fundraiser can list NFT tokens in a DEX platform and do crowdfunding by establishing a token sale through DEX.
                  </p>
                </div>
                <div className="square" >
                  <h3>Initial Exchange Offering</h3>
                  <p className="pharagraph">
                  Initial Exchange Offering allows you to list the NFTs in exchange and initiate the fundraising process quickly. The IEO launchpad of the particular exchange connects with global NFT investors and conducts crowdfunding while charging a certain fee or commission.
                  </p>
                </div>
                <div className="square" >
                  <h3>Initial Farm Offering</h3>
                  <p className="pharagraph">
                  Initial Farm Offering is a different fundraising method, where fundraisers give their NFTs to the decentralized platform’s liquidity pool. The fundraiser will receive rewards that can be used as capital investment for their NFT project.
                  </p>
                </div>
                <div className="square" >
                  <h3>Initial Game Offering</h3>
                  <p className="pharagraph">
                  Initial Game Offering is a growing crowdfunding method where fundraisers sell their game assets on NFT launchpad platforms and gather funds for the NFT project. The launchpad will gather certain perks like fees or commissions for fundraising.
                  </p>
                </div>
                <div className="square" >
                  <h3>Initial Insurance Offering</h3>
                  <p className="pharagraph">
                  The Initial Insurance Offering (IIO) is a different fundraising concept that aims to attract NFT investors and help fundraisers raise funding for NFT projects. The IIO platform creates tokens on prominent blockchain and conducts token sales.
                  </p>
                </div>
              </div>
            </div>
          </div>
      </section>
    )
  }
}

export default Benificial
